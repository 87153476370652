<template>

    <div id="login">
        <div class="topbar">
            <div class="lf">
                <div class="logo">
                    <img :src="require('@/assets/images/education/logo_bottom.png')" alt />
                </div>
                <div class="slogan">
                    <img :src="require('@/assets/images/font_banbao.png')" alt="">
                </div>
                <span>STEAM教育和创客教育领域先行者</span>
            </div>
            <div class="rf">
                <router-link to="/education?n=OTA%3D">
                    <i class="icon icon-education"></i>
                    邦宝教育
                </router-link>
                <router-link to="/">
                    <i class="icon icon-home"></i>
                    返回主站
                </router-link>
            </div>
        </div>
        <div class="content">
            <div class="lf">
                <div class="member">
                    <h2>邦宝会员</h2>
                    <p><i class="icon-right"></i>下载相关教材、视频及说明书</p>
                    <p><i class="icon-right"></i>学习邦宝教育站所有免费STEAM教育/创客教育课程</p>
                    <p><i class="icon-right"></i>免费发布Scratch3.0的原创作品</p>
                </div>
                <div class="service">
                    <h2>课堂服务</h2>
                    <span>为以下老师量身打造</span>
                    <ul>
                        <li v-for="(item, index) in services" :key="index">
                            <i class="icon-right"></i>
                            <span>{{ item }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="rf">
                <div class="types" v-if="editorType !== 2">
                    <span @click="editorClicked(0)" :class="{'actived': editorType === 0}">登录</span>
                    <span @click="editorClicked(1)" :class="{'actived': editorType === 1}">注册</span>
                </div>
                <div class="types" v-else>
                    <span :class="{'actived': editorType === 2}">找回密码</span>
                </div>
                <form class="form" @keyup.enter="loginHandle">
                    <div class="login-form" v-if="editorType === 0 || editorType === 2">
                        <div class="item username">
                            <i class="icon icon-user"></i>
                            <input type="text" v-model="username" name="username" placeholder="请输入用户名"/>
                        </div>
                        <div class="item mail" v-if="editorType === 2">
                            <i class="icon icon-mail"></i>
                            <input type="text" v-model="userMail" @blur="validMail" name="userMail" placeholder="请输入邮箱"/>
                        </div>
                        <div class="item password" v-if="editorType === 0">
                            <i class="icon icon-pwd"></i>
                            <input type="password" v-model="password" name="password" placeholder="请输入登录密码"/>
                        </div>
                        <div class="item code">
                            <i class="icon icon-safe"></i>
                            <input type="text" v-model="code" name="code" placeholder="请输入验证码"/>
                            <img :src="imgCode" @click="getImgcode" alt="">
                        </div>
                        <div class="find-pwd">
                            <span @click="editorType = 0">登录</span>
                            <span @click="editorType = 2">找回密码</span>
                        </div>
                        <div class="btn">
                            <button type="button" @click="findPwdHandle" v-if="editorType === 2">发 送</button>
                            <button type="button" @click="loginHandle" v-else>登 录</button>
                        </div>
                        <div class = "tip">
                            温馨提示：如账户使用有疑问，请联系我们。
                        </div>
                    </div>
                    <div class="register-form" v-else>
                        <div class="item username">
                            <i class="icon icon-user"></i>
                            <input type="text" v-model="username" placeholder="请输入用户名"/>
                        </div>
                        <div class="item password">
                            <i class="icon icon-pwd"></i>
                            <input type="password" v-model="userPass" placeholder="请输入登录密码"/>
                        </div>
                        <div class="item repassword">
                            <i class="icon icon-pwd"></i>
                            <input type="password" v-model="repassword" placeholder="请再次输入登录密码"/>
                        </div>
                        <div class="item ">
                            <i class="icon icon-contact"></i>
                            <input type="text" v-model="userContact" placeholder="请输入真实姓名"/>
                        </div>
                        <div class="item ">
                            <i class="icon icon-email"></i>
                            <input type="text" v-model="userMail" placeholder="请输入电子邮箱用于之后找回密码"/>
                        </div>
                        <div class="item phone">
                            <i class="icon icon-phone"></i>
                            <input type="tel" maxlength="11" v-model="userPhone" placeholder="请输入自己或家长的手机号码"/>
                        </div>
                        <div :class="['item', 'agree', (userTitle !== '' && file !== '') ? 'applied' : '']" @click="agree = true">
                            <i class="icon-arrow"></i>
                            <span>申请成为老师</span>
                        </div>
                        <!--<div class="phone-code">
                            <input type="text" v-model="phoneCode" maxlength="6" placeholder="请输入"/>
                            <button type="button" :disabled="banned" @click="getPhoneCode">{{ banned ? '重新获取('+ count +')' : '获取验证码' }}</button>
                        </div>-->
                        <div class="code">
                            <i class="icon icon-safe"></i>
                            <input type="text" v-model="code" name="code" placeholder="请输入验证码"/>
                            <img :src="imgCode" @click="getImgcode" alt="">
                        </div>
                        <div class="btn">
                            <button type="button" class="btn-register" @click="registerHandle">注 册</button>
                        </div>
                        <div class = "tip">
                            温馨提示：如账户使用有疑问，请联系我们。
                        </div>
                    </div>
                    <v-overlay :value="agree">
                        <div class="teacher-apply">
                            <div class="icon-close" @click="handleCloseBecomeATeacher">&times;</div>
                            <h2>申请成为老师</h2>
                            <div class="item">
                                <i class="icon icon-title"></i>
                                <input type="tel" v-model="userTitle" placeholder="请输入老师的头衔"/>
                            </div>
                            <div class="item intr">
                                <p>
                                    <i class="icon icon-intr"></i>
                                    <span>请输入老师简介</span>
                                </p>
                                <textarea v-model="resume" rows="3" maxlength="120"></textarea>
                            </div>
                            <div class="upload item">
                                <label for="uploader">
                                    <i class="icon icon-upload"></i>
                                    上传资质
                                </label>
                                <span>{{ file.name }}</span>
                                <i class="icon-close t--8 r--10" @click="file = ''" v-show="file">&times;</i>
                                <input type="file" id="uploader" accept=".zip,.rar" @change="uploadFile" style="display: none;">
                            </div>
                            <div class="tips">
                                <h3>申请成为老师须知：请将下列证明文件打包上传：</h3>
                                <p>1.身份证（常见图片格式）</p>
                                <p>2.教师资格证或行业证书（常见图片格式）</p>
                                <p>3.简历（PDF或者WORD格式）</p>
                                <p>4.上课短视频（3分钟内的MP4格式，30M以内）</p>
                            </div>
                            <div class="btn">
                                <div type="button" @click="handleCheckBecomeATeacher">确定</div>
                            </div>
                        </div>
                    </v-overlay>
                </form>
            </div>
        </div>
        <component-education-bottom :showScratchBg="true"></component-education-bottom>
    </div>

</template>

<script>

    import ComponentEducationBottom from '@/components/ComponentEducationBottom'
    import {urls, banbaoUrl} from '@/lib/common';
    export default {
        name: "Login",
        components: {
            ComponentEducationBottom
        },
        data() {
            return {
                banbaoUrl,
                services: ['编程教育', '人工智能教育', 'STEAM教育', '创客教育', '信息技术教育', '机器人教育'],
                editorType: 0,
                code: '',
                username: '',
                password: '',
                repassword: '',
                userMail: '',
                userContact: '',
                userPhone: '',
                userPass: '',
                imgCode: '',
                banned: false,
                agree: false,
                count: 60,
                file: '',
                accessory: '', // 资质文件路径
                userTitle: '',
                resume: '',

            };
        },
        methods: {
            editorClicked(type) {
                this.getImgcode()
                this.username = '';
                this.password = '';
                this.repassword = '';
                this.code = '';

                this.userContact = '';
                this.userPhone = '';
                this.userMail = '';
                this.userPass = '';
                this.file = '';
                this.userTitle = '';
                this.resume = '';
                this.editorType = type;
            },
            S4() {
                return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
            },
            guid() {
                return (this.S4()+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+"-"+this.S4()+this.S4()+this.S4());
            },
            getPhoneCode() {
                let validResult = this.validate();
                if (!validResult) {
                    this.$message.warning('请输入正确手机号码')
                    return false
                }
                this.banned = true;
                const url = urls.phoneCode + '?phone=' + this.userPhone;
                this.$http.get(url).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('短信已发送,请注意接收')
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
                let timer = setInterval(() => {
                    this.count--;
                    if (this.count < 0) {
                        this.banned = false;
                        clearInterval(timer);
                        timer = null;
                        this.count = 60;
                    }
                }, 1000)
            },
            validate() { // 格式验证
                const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                return phoneReg.test(this.userPhone);
            },
            validMail() {
                const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
                if (!reg.test(this.userMail)) {
                    this.$message.warning('请输入正确邮箱格式');
                    return false
                } else {
                    return true
                }
            },
            getImgcode() { // 获取图形验证码
                this.uuid = this.guid();

                this.$http.get(urls.imgCode + this.uuid).then(res => {
                    if (res.data.status !== 200) {
                        this.$message.error(res.data.message);
                    } else {
                        this.imgCode = res.data.data;
                    }
                })
            },
            loginHandle() { // 登录
                if (!this.username) {
                    this.$message.error("请输入用户名");
                    return false
                }
                if (!this.password) {
                    this.$message.error("请输入登录密码");
                    return false
                }
                if (!this.code) {
                    this.$message.error("请输入图形验证码");
                    return false
                }
                const params = {
                    username: this.username,
                    password: this.password,
                    uuid: this.uuid,
                    imgCode: this.code
                }

                this.$http.post(urls.login, params).then(res => {
                    this.getImgcode()
                    if (res.data.status === 200) {
                        this.$message({
                            type: 'success',
                            message: "登录成功!~"
                        });
                        this.loginCallback(res);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            },
            loginCallback(res) {
                console.log(res);
                this.$cookies.set('app.platform', 'website', {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.token', res.data.data.token, {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.uid', res.data.data.user.userId, {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.username', res.data.data.user.username, {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.email', res.data.data.user.email, {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.nickname', res.data.data.user.realName, {domain: banbaoUrl, expires: 1, path: ''});
                this.$cookies.set('app.roles', res.data.data.roles[0], {domain: banbaoUrl, expires: 1, path: ''});

                sessionStorage.setItem('token', res.data.data.token);
                sessionStorage.setItem('roles', res.data.data.roles[0]);
                sessionStorage.setItem('userinfo', JSON.stringify(res.data.data.user));
                this.$store.commit("saveUsername", res.data.data.user.username);
                
                const prevPath = JSON.parse(sessionStorage.getItem('prevPath'))
                if (prevPath) {
                    sessionStorage.removeItem('prevPath');
                    let obj = {
                        name: prevPath.pathName,
                        params: prevPath.params
                    }
                    if (prevPath.query) obj.query = prevPath.query;
                    this.$router.push(obj)
                } else {
                    this.$router.push({
                        path: '/userCenter'
                    })
                }
            },
            findPwdHandle() {
                if (!this.username) {
                    this.$message.warning('请输入用户名');
                    return
                }
                if (!this.validMail()) {
                    return
                }
                if (!this.code || this.code.length !== 4) {
                    this.$message.warning('请输入正确验证码')
                    return
                }
                const params = {
                    username: this.username,
                    userMail: this.userMail,
                    imgCode: this.code,
                    uuid: this.uuid
                }
                this.$http.post(urls.findPwd, params).then(res => {
                    if (res.data.status === 200) {
                        if (res.data.message === 'success') this.$message.success('已发送,请留意邮箱信息');
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            registerHandle() { // 注册
                if (!this.username || this.username.length < 6) {
                    this.$message.warning('请输入长度大于6位的账号');
                    return false
                }
                if (!this.userPass || this.userPass.length < 6) {
                    this.$message.warning('请输入长度大于6位的密码');
                    return false
                }
                if (!this.repassword) {
                    this.$message.warning('请再次输入登录密码');
                    return false
                } else if (this.repassword !== this.userPass) {
                    this.$message.warning('密码不一致,请重新输入');
                    return false
                }
                if (!this.userContact) {
                    this.$message.warning('请输入姓名');
                    return false
                }
                if (!this.userMail) {
                    this.$message.warning('请输入邮箱');
                    return false
                }
                const reg = /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/;
                if (!reg.test(this.userMail)) {
                    this.$message.warning('请输入正确邮箱格式');
                    return false
                }
                if (!this.userPhone) {
                    this.$message.warning('请输入自己或家长的电话号码');
                    return false;
                }
                const phoneReg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
                if(!phoneReg.test(this.userPhone)) {
                    this.$message.warning('请输入正确的电话号码格式');
                    return false;
                }
                if (!this.code || this.code.length !== 4) {
                    this.$message.warning('请输入正确的验证码');
                    return false
                }

                const params = {
                    userName: this.username,
                    userPass: this.userPass,
                    userContact: this.userContact,
                    userMail: this.userMail,
                    code: this.code,
                    uuid: this.uuid
                }
                if (this.userPhone) params.userPhone = this.userPhone;
                if (this.file) {
                    params.accessory = this.accessory;
                    params.type = 1;
                } else {
                    params.type = 0;
                }
                if (this.userTitle) params.title = this.userTitle;
                if (this.resume) params.resume = this.resume;
                this.$http.post(urls.register, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message({
                            type: 'success',
                            message: "注册成功!~"
                        });
                        this.loginCallback(res)
                        this.username = "";
                        this.userPass = "";
                        this.repassword = "";
                        this.userContact = "";
                        this.code = "";
                        this.userPhone = "";
                        this.resume = '';
                        this.file = "";
                        this.agree = false;
                        this.editorType = 0;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },
            checkFileType(str) {
                const pos = str.lastIndexOf(".");
                const lastname = str.substring(pos,str.length);
                if ((lastname.toLowerCase() !== ".zip" )&&(lastname.toLowerCase() !== ".rar")) {
                    this.$message.warning('请上传zip或rar压缩格式文件');
                    return false;
                } else {
                    return true
                }
            },
            uploadFile(e) { // 上传资质
                if (e.target.files.length > 0) {
                    const file = e.target.files[0];
                    if (this.checkFileType(file.name)) {
                        this.file = file;
                        const params = new FormData();
                        params.append("file", file)
                        this.$http.post(urls.qualificationUpload, params).then(res => {
                            if (res.data.status === 200) {
                                this.$message.success('资料上传成功');
                                this.accessory = res.data.data;
                            } else if (res.data.status !== 401 && res.data.status !== 106) {
                                this.$message.error(res.data.message)
                            }
                        })
                    }
                }
            },
            teacherRegister() { // 教师注册
                const {userTitle: title, resume, file: accessory} = this;
                if (!accessory) {
                    this.$message.error("请上传资质文件");
                    return false
                }
                const params = {accessory}
                if (title) params.title = title;
                if (resume) params.resume = resume
                this.$http.post(urls.teacherApply, params).then(res => {
                    if (res.data.status === 200) {
                        this.$message.success('申请成功，请等待审核.');
                        this.agree = false;
                        this.editorType = 0;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            },

            handleCheckBecomeATeacher: function() {
                let that = this;

                if(!that.userTitle) {
                    that.$message.warning('请输入老师的头衔');
                    return false;
                }
                if (!that.file) {
                    that.$message.warning('请上传资质');
                    return false;
                }

                that.agree = false;
            },
            handleCloseBecomeATeacher: function() {
                this.agree = false;
                this.userTitle = '';
                this.file = '';
                this.resume = '';
            },
        },
        created() {
            this.token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
            this.getImgcode();
        },
        mounted() {
            if (this.$route.query && (Number(this.$route.query.editorType) === 0 || Number(this.$route.query.editorType) === 1 || Number(this.$route.query.editorType) === 2)) {
                this.editorType = Number(this.$route.query.editorType);
            }
            if (this.$cookies.get('app.token', {domain: banbaoUrl, path: ''})) {
                this.$router.push({
                    path: '/userCenter'
                })
            }
        }
    };

</script>

<style lang="less" scoped>

    @deep: ~'>>>';
    #login {
        .topbar {
            width: 100%;
            min-width: 1024px;
            margin: auto;
            padding: 1.5vw 12.5vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            z-index: 99;
            position: absolute;
            left: 0;
            top: 0;
            height: 5.5vw;
            .lf {
                width: 65%;
                box-sizing: border-box;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .logo {
                    margin-right: 2vw;
                    width: 23%;
                }
                .slogan {
                    width: 25%;
                    display: flex;
                    align-items: center;
                    box-sizing: border-box;
                    padding-left: 1vw;
                    border-left: 1px solid #fff;

                }
                >span {
                    color: #fff;
                    display: inline-block;
                    padding: 3px 10px;
                    border-radius: 20px;
                    border: 1px solid #fff;
                }
            }
            .rf {
                text-align: right;
                box-sizing: border-box;
                line-height: 200%;
                a {
                    display: inline-block;
                    margin-left: 2vw;
                    color: #02A5E6;
                    font-size: 16px;
                    &:last-child {
                        color: #D93C45;
                    }
                    .icon {
                        display: inline-block;
                        width: 22px;
                        height: 22px;
                        background: url("../assets/images/icon_education.png") no-repeat center center;
                        background-size: 100%;
                        position: relative;
                        top: 4px;
                    }
                    .icon-home {
                        background-image: url("../assets/images/icon_home.png");
                    }
                }
            }
        }
        .content {
            min-height: 62vw;
            box-sizing: border-box;
            margin: auto;
            padding: 5.5vw 15vw;
            color: #fff;
            background: #F2FCFF url("../assets/images/login_bg.png") no-repeat left top;
            background-size: 100%;
            .lf {
                float: left;
                width: 50%;
                margin-top: 8%;
                .member,
                .service {
                    position: relative;
                    padding: 2vw 1vw 1vw;
                    border-radius: 10px;
                    background: rgba(255, 255, 255, 0.2);
                    line-height: 200%;
                    font-size: 20px;
                    &.member {
                        margin-bottom: 3vw;
                    }
                    h2 {
                        position: absolute;
                        left: 0;
                        top: -0.9em;
                        color: #1BB2FF;
                        background: #fff;
                        padding: 5px 1.8vw;
                        border-radius: 6px;
                        line-height: normal;
                    }
                    .icon-right {
                        display: inline-block;
                        width: 1.45vw;
                        height: 1.45vw;
                        background: url("../assets/images/icon_right.png") no-repeat center center;
                        background-size: 100% 100%;
                        position: relative;
                        top: .3vw;
                        margin-right: 10px;
                    }
                    &.service {
                        >span {
                            font-size: 0.9375vw;
                        }
                        ul {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            li {
                                width: 50%;
                            }
                        }
                    }
                }
            }
            .rf {
                float: right;
                width: 45%;
                background-color: #fff;
                border-radius: 10px;
                padding: 3vw 5vw;
                box-sizing: border-box;
                margin-top: 3%;
                box-shadow: 0px 10px 30px rgba(52, 37, 76, 0.11);
                .types {
                    text-align: center;
                    font-size: 24px;
                    color: #A2A2A2;
                    border-bottom: 1px solid #ddd;
                    span {
                        display: inline-block;
                        margin-right: 1vw;
                        line-height: 200%;
                        cursor: pointer;
                        border-bottom: 3px solid transparent;
                        &.actived {
                            border-bottom-color: #18A9FF;
                            color: #18A9FF;
                            transition: all .3s linear;
                        }
                        &:last-child {
                            margin: 0;
                        }
                    }
                }
                .login-form,
                .register-form {
                    >div {
                        line-height: 2.6vw;
                        font-size: 14px;
                        margin-top: 2vw;
                        position: relative;
                        &:last-child {
                            border-bottom: 0;
                            color: #65C4EB;
                        }
                        &.agree {
                            width: 100%;
                            text-align: center;
                            border: 1px solid #02A5E6;
                            border-radius: 4px;
                            span {
                                color: #606060;
                                cursor: pointer;
                            }
                            .icon-arrow {
                                display: inline-block;
                                position: relative;
                                top: 2px;
                                border: 1px solid #DCDFE6;
                                border-radius: 2px;
                                box-sizing: border-box;
                                width: 14px;
                                height: 14px;
                                background-color: #FFF;
                                z-index: 1;
                                margin-right: 5px;
                                transition: border-color .25s cubic-bezier(.71,-.46,.29,1.46),background-color .25s cubic-bezier(.71,-.46,.29,1.46);
                                &::after {
                                    box-sizing: content-box;
                                    content: "";
                                    border: 1px solid #FFF;
                                    border-left: 0;
                                    border-top: 0;
                                    height: 7px;
                                    left: 4px;
                                    position: absolute;
                                    top: 1px;
                                    transform: rotate(45deg) scaleY(0);
                                    width: 3px;
                                    transition: transform .15s ease-in .05s;
                                    transform-origin: center;
                                }
                            }
                            &.applied {
                                span {
                                    color: #409EFF;
                                }
                                .icon-arrow {
                                    background-color: #409EFF;
                                    border-color: #409EFF;
                                    &::after {
                                        transform: rotate(45deg) scaleY(1);
                                    }
                                }
                            }
                        }
                        .icon {
                            display: inline-block;
                            position: absolute;
                            left: 8px;
                            top: 50%;
                            width: 20px;
                            height: 20px;
                            margin-top:-10px;
                            background: url("../assets/images/icon_user.png") no-repeat center center;
                            background-size: contain;
                            &.icon-pwd {
                                background-image: url("../assets/images/icon_pwd.png");
                            }
                            &.icon-safe {
                                background-image: url("../assets/images/icon_safe.png");
                            }
                            &.icon-contact {
                                background-image: url("../assets/images/icon_contact.png");
                            }
                            &.icon-email {
                                background-image: url("../assets/images/icon_mail.png");
                            }
                            &.icon-phone {
                                background-image: url("../assets/images/icon_phone.png");
                            }
                            &.icon-mail {
                                background-image: url("../assets/images/icon_mail.png");
                            }
                        }
                        input {
                            width: 100%;
                            font-size: 16px;
                            box-sizing: border-box;
                            padding-left: 32px;
                            color: #333;
                            background-color: #F2FCFF;
                            border: 1px solid #C6F2FF;
                            border-radius: 4px;
                            &::placeholder{
                                color: #65C4EB;
                            }
                        }
                        &.phone {
                            label {
                                width: 30%;
                            }
                        }
                        &.code {
                            input {
                                float: left;
                                width: 74%;
                            }
                            img {
                                float: right;
                                width: 25%;
                                height: 2.6vw;
                                cursor: pointer;
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }

                        }

                        &.phone-code {
                            input {
                                width: 60%;
                            }
                            button {
                                width: 38%;
                                float: right;
                                background: #FB8C00;
                                color: #fff;
                                padding: 0 15px;
                                &:disabled {
                                    background: #aaa;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 2vw;
                        button {
                            font-size: 20px;
                            width: 100%;
                            text-align: center;
                            background-color: #24CEFF;
                            color: #fff;
                            border-radius: 6px;
                        }
                    }
                }
                .login-form {
                    .btn {
                        margin-top: 10px;
                    }
                }
                .find-pwd {
                    display: flex;
                    justify-content: space-between;
                    color: #65C4EB;
                    margin-top: 10px !important;
                    span {
                        cursor: pointer;
                    }
                }
                @{deep} .v-overlay {
                    z-index: 99 !important;
                }
                @{deep} .v-overlay__content {
                    width: 30vw;
                }
                .teacher-apply {
                    background: #fff;
                    position: relative;
                    color: #333;
                    border-radius: 10px;
                    padding: 3vw 5vw;
                    line-height: normal;
                    .icon-close {
                        font-size: 30px;
                        position: absolute;
                        top: 0;
                        right: 0.8vw;
                        color: #999;
                        cursor: pointer;
                        line-height: normal;
                    }
                    h2 {
                        font-size: 30px;
                        text-align: center;
                    }
                    .item {
                        line-height: 2.6vw;
                        font-size: 14px;
                        margin-top: 1vw;
                        position: relative;
                        width: 20vw;
                        &.intr {
                            padding: 0 6px 10px;
                            background-color: #F2FCFF;
                            border-radius: 4px;
                            border: 1px solid #C6F2FF;
                            >p {
                                >span {
                                    display: inline-block;
                                    margin-left: 5px;
                                    color: #65C4EB;
                                }
                            }
                            textarea {
                                width: 100%;
                                line-height: 150%;
                                resize: none;
                            }
                        }
                        &.upload {
                            line-height: normal;
                            label {
                                float: left;
                                width: 8vw;
                                background: #D93C45;
                                color: #fff;
                                padding: 5px 0;
                                border-radius: 40px;
                                margin-right: 1vw;
                                cursor: pointer;
                                text-align: center;
                                .icon {
                                    position: relative;
                                    top: 0.3vw;
                                    left: 0;
                                }
                            }
                            >span {
                                float: left;
                                width: 11vw;
                                word-break: break-all;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                position: relative;
                                top: 0.5vw;
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                        }
                        .icon {
                            display: inline-block;
                            position: absolute;
                            left: 8px;
                            top: 0.7vw;
                            width: 20px;
                            height: 20px;
                            background: url("../assets/images/icon_user.png") no-repeat center center;
                            background-size: contain;
                            &.icon-title {
                                background-image: url("../assets/images/icon_title.png");
                            }
                            &.icon-intr {
                                background-image: url("../assets/images/icon_intr.png");
                                position: relative;
                                top: 0.4vw;
                                left: 0;
                            }
                            &.icon-upload {
                                background-image: url("../assets/images/icon_license.png");
                            }
                        }
                        input {
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 2vw;
                            color: #333;
                            background-color: #F2FCFF;
                            border: 1px solid #C6F2FF;
                            border-radius: 4px;
                            &::placeholder{
                                color: #65C4EB;
                            }
                        }
                    }
                    .tips {
                        line-height: 200%;
                        color: #606060;
                        font-size: 14px;
                        margin-top: 1vw;
                        color: #65C4EB;
                        h3 {
                            color: #DC000C;
                        }

                    }
                    >.btn {
                        background-color: #1BB2FF;
                        color: #fff;
                        border-radius: 4px;
                        font-size: 1.25vw;
                        text-align: center;
                        margin-top: 2vw;
                        line-height: 2.9vw;
                    }
                }
            }
            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }
    }

    .t--8 {top: -8px !important;}
    .r--10 {right: -10px !important;}

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #login{
            
            .topbar{
                background: linear-gradient(63deg, #23CBFC 0%, #24CEFF 100%);
                position: relative;
                min-width: auto;
                padding: 2.2% 5.2% 4%;
                height: auto;
                .lf{
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;
                    .logo{
                        width: 56%;
                        padding-right: 4%;
                        margin-right: 0;
                    }
                    .slogan{
                        width: 40%;
                        padding-left:4%;
                        position: relative;
                        top:-6px;
                    }
                    &>span{
                        width: 100%;
                        .fontFn(18);
                        text-align: center;
                        margin-top:4%;
                    }
                }
                .rf{
                    display: none;
                }
            } 
            .content{
                display: flex;
                flex-direction: column-reverse;
                background:url('../assets/images/phone/login_bg.png') no-repeat center bottom;
                background-size:100% 100%;
                padding: 0 2.6% 15%;
                .lf{
                    float: none;
                    padding-top: 6%;
                    width: 100%;
                    .member,
                    .service{
                        .fontFn(18);
                        padding:8% 5.2% 5.2%;
                        margin-bottom: 14%;
                        &.member{
                            margin-bottom: 14%;
                        }
                        &>span{
                            color:rgba(255,255,255,0.6);
                            .fontFn(13);
                        }
                        h2{
                            .fontFn(18);
                            padding: 5px 8%;
                        }
                        .icon-right{
                            .fontFn(21,width);
                            .fontFn(21,height);
                        }
                    }
                }
                .rf{
                    float: none;
                    width: 100%;
                    padding:8% 2%;
                    .types{
                        span{
                            .fontFn(24);
                            margin-right: 8%;
                        }
                        span.actived{

                        }
                    }
                    .teacher-apply{
                        &>.btn{
                            .fontFn(16);
                            margin-top:3%;
                            .fontFn(36,height);
                            .fontFn(36,line-height);
                        }
                    }
                    .login-form,
                    .register-form{
                        padding-top: 10%;
                        &>div{
                            margin-bottom: 10%;
                            margin-top:0;
                            .icon{
                                .fontFn(20,width);
                                .fontFn(20,height);
                                top:50%;
                                left:4%;
                                margin-top:0;
                                transform: translateY(-50%);
                                -webkit-transform: translateY(-50%);
                                -moz-transform: translateY(-50%);
                                -o-transform: translateY(-50%);
                                -ms-transform: translateY(-50%);
                            }
                            input{
                                .fontFn(12);
                                .fontFn(42,height);
                                .fontFn(42,line-height);
                                padding-left: 12%;
                            }
                        }
                        &>div.code img{
                            .fontFn(42,height);
                        }
                        &>div.agree{
                            .fontFn(42,line-height);
                        }
                        .btn{
                            button{
                                .fontFn(20);
                                font-weight: bold;
                                .fontFn(46,height);
                                .fontFn(46,line-height);
                            }
                        }
                        .tip{
                            color: #65C4EB;
                            .fontFn(15);
                            margin-bottom: 4%;
                        }
                    }
                    @{deep} .v-overlay__content{
                        width: 90%;
                        .teacher-apply{
                            padding: 8% 4%;
                            .icon-close{
                                .fontFn(24);
                                .fontFn(36,width);
                                .fontFn(36,height);
                                text-align: center;
                            }
                            h2{
                                .fontFn(18);
                                padding-bottom: 4%;
                            }
                            .item{
                                width: 100%;
                                .icon{
                                    .fontFn(20,width);
                                    .fontFn(20,height);
                                }
                                .icon.icon-title{
                                    top:50%;
                                    transform: translateY(-50%);
                                    -webkit-transform: translateY(-50%);
                                    -moz-transform: translateY(-50%);
                                    -ms-transform: translateY(-50%);
                                    -o-transform: translateY(-50%);
                                }
                                .icon.icon-intr{
                                    top:6px;
                                }
                                input{
                                    padding: 3% 10%;
                                }
                                &.intr{
                                    textarea{
                                        margin-top:10px;
                                    }
                                }
                                &.upload{
                                    margin-top:4%;
                                    label{
                                        width: 40%;
                                        padding: 4px 0 8px;
                                        .fontFn(14);
                                        .icon{
                                            top:6px;
                                        }

                                    }
                                }
                            }
                            .tips{
                                .fontFn(12);
                            }
                        }
                    }
                }
            }
        }
    }

</style>